<div class="form tw-relative" [class]="{ 'tw-p-12': isUpdateVendorRoute }">
  <div [class]="isUpdateVendorRoute ? 'tw-p-4 tw-bg-white tw-rounded-md' : ''">
    <div class="tw-px-7 tw-pt-5 tw-pl-3">
      @if (isUpdateVendorRoute && vendor) {
        <div class="tw-flex tw-gap-8">
          <div class="mb-4">
            <img [src]="store.brandMeta()?.logo" [alt]="store.brandMeta()?.shortName" width="70" />
          </div>
        </div>
      }
      @if (vendor === null) {
        <h2 class="tw-font-bold">Add Vendor Master</h2>
      } @else if (vendor) {
        <h2 class="tw-font-bold">Update Vendor Master</h2>
        <p class="help-text tw-italic tw-mt-2">
          Last Updated By {{ vendor.updatedByName }} on {{ vendor.updateDate | date: 'dd MMM yyyy, HH:mm' }}
        </p>
      }
      @if (isUpdateVendorRoute && vendor) {
        <div class="mt-2">
          <p class="help-text">Review your company information as provided by {{ vendor.ctpyName }}</p>
          <ul class="tw-list-disc tw-ml-6 tw-my-2">
            <li class="help-text">
              If you need to make updates or corrections, click Save As Draft to save your changes.
            </li>
            <li class="help-text">
              If the information is complete and accurate, click Save to finalize and submit your company information.
            </li>
          </ul>
        </div>
      }
    </div>

    <form [formGroup]="addMasterCustomerForm">
      <!-- <h2>Basic Info</h2> -->
      <div class="tw-flex tw-gap-5 tw-mt-3">
        <div class="tw-flex-1">
          <div class="flex-baseline flex-equal tw-pl-3 tw-pr-3">
            <div class="form-group">
              <label class="required">Issuer TIN</label>
              <select class="form-select w-10" formControlName="issuerTin">
                <option value="">- Select -</option>
                @for (item of tinsList; track item) {
                  <option [value]="item.tin">[{{ item.tin }}]{{ item.companyName }}</option>
                }
              </select>
              <div class="input-error">
                @if (addMasterCustomerForm.controls['issuerTin'].errors) {
                  <p>{{ formErrors.tin }}</p>
                }
              </div>
            </div>
            <div class="form-group">
              <label class="required">Category</label>
              <div>
                <label class="radio tw-pb-8">
                  @for (option of categoryOptions; track $index) {
                    <input
                      type="radio"
                      formControlName="category"
                      [value]="option"
                      (change)="onCategoryChange(option)"
                    />
                    {{ option }}
                  }
                </label>
              </div>
              <div class="input-error">
                @if (addMasterCustomerForm.controls['category'].errors) {
                  <p>{{ formErrors.category }}</p>
                }
              </div>
            </div>
            <div class="form-group">
              <label class="required">ID Type</label>
              <div>
                <label class="radio tw-pb-8">
                  @for (option of counterPartyIDType; track $index) {
                    <input type="radio" formControlName="ctpyIdType" [value]="option" />
                    {{ option }}
                  }
                </label>
              </div>
            </div>
          </div>

          <div class="flex-baseline flex-equal mb-3 tw-pl-3 tw-pr-3">
            <div class="form-group">
              <label class="required">CounterParty TIN</label>
              <input
                type="text"
                class="form-control"
                formControlName="ctpyTin"
                placeholder="CounterParty TIN"
                appInputText
              />
              <div class="input-error">
                @if (addMasterCustomerForm.controls['ctpyTin'].errors) {
                  <p>{{ formErrors.tin }}</p>
                }
              </div>
            </div>

            <div class="form-group">
              <label class="required">CounterParty Name</label>
              <input type="text" class="form-control" formControlName="ctpyName" placeholder="CounterParty Name" />
              <div class="input-error">
                @if (addMasterCustomerForm.controls['ctpyName'].errors) {
                  <p>{{ formErrors.counterPartyName }}</p>
                }
              </div>
            </div>
            <div class="form-group">
              <label class="required">Counterparty ID Number</label>
              <div>
                <input
                  type="text"
                  placeholder="Counterparty ID Number"
                  formControlName="ctpyIdNo"
                  class="form-control"
                  required
                />
                <div class="input-error">
                  @if (addMasterCustomerForm.controls['ctpyIdType'].value === 'BRN') {
                    <p>{{ formErrors.counterPartyNo1 }}</p>
                  } @else if (
                    addMasterCustomerForm.controls['ctpyIdType'].value === 'NRIC' ||
                    addMasterCustomerForm.controls['ctpyIdType'].value === 'Passport' ||
                    addMasterCustomerForm.controls['ctpyIdType'].value === 'ARMY'
                  ) {
                    <p>{{ formErrors.counterPartyNo2 }}</p>
                  }
                </div>
              </div>
            </div>
          </div>

          <div formArrayName="lineItems">
            @for (item of getItemsControls(); track $index) {
              <div [formGroupName]="$index">
                <button class="group-title" (click)="toggleCollapse($index)">
                  Line Item {{ $index + 1 }}

                  <i
                    [class]="{
                      'gg-chevron-down': !addMasterCustomerForm.value.lineItems[$index].isCollapse,
                      'gg-chevron-up': addMasterCustomerForm.value.lineItems[$index].isCollapse,
                    }"
                  ></i>
                </button>
                <div
                  class="tw-p-3 tw-flex tw-justify-between tw-flex-col"
                  [class.collapse]="!addMasterCustomerForm.value.lineItems[$index].isCollapse"
                >
                  <div class="tw-max-w-[50rem] tw-gap-8 mb-2">
                    <!-- //COunetrParty divs -->
                    <div>
                      <p>CounterParty</p>
                      <div class="row tw-pt-3">
                        @if (categoryValue !== 'Customer') {
                          <div class="col-3">
                            <label class="required">Tourism Tax Number</label>
                            <input
                              type="text"
                              class="form-control"
                              formControlName="ctpyTtrNo"
                              multiple
                              title="Enter multiple values separated by commas"
                              [required]="isVendor"
                            />
                            <div class="input-error">
                              @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyTtrNo')?.errors) {
                                <p>{{ formErrors.tourismTaxNumber }}</p>
                              }
                            </div>
                          </div>
                        }

                        <div class="col-3">
                          <label class="required">Unique Identifier</label>
                          <input type="text" class="form-control" formControlName="uniqueIdentifier" required />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.uniqueIdentifier')?.errors) {
                              <p>{{ formErrors.uniqueIdentifier }}</p>
                            }
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="required">SST Registration Number </label>
                          <input type="text" class="form-control" formControlName="ctpySstrNo" [required]="true" />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpySstrNo')?.errors) {
                              <p>{{ formErrors.sstRegistrationNumber }}</p>
                            }
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="required">Address Line 0</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="ctpyAddrLine0"
                            required
                            appInputText
                          />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyAddrLine0')?.errors) {
                              <p>{{ formErrors.address }}</p>
                            }
                          </div>
                        </div>
                      </div>

                      <div class="row tw-pt-3">
                        <div class="col-3">
                          <label>Address Line 1</label>
                          <input type="text" class="form-control" formControlName="ctpyAddrLine1" appInputText />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyAddrLine1')?.errors) {
                              <p>{{ formErrors.address }}</p>
                            }
                          </div>
                        </div>
                        <div class="col-3">
                          <label>Address Line 2</label>
                          <input type="text" class="form-control" formControlName="ctpyAddrLine2" appInputText />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyAddrLine2')?.errors) {
                              <p>{{ formErrors.address }}</p>
                            }
                          </div>
                        </div>

                        <div class="col-3">
                          <label class="required">Postal Zone</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="ctpyPostalZone"
                            required
                            appInputText
                          />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyPostalZone')?.errors) {
                              <p>{{ formErrors.postalZone }}</p>
                            }
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="required">City Name</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="ctpyCityName"
                            required
                            appInputText
                          />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyCityName')?.errors) {
                              <p>{{ formErrors.city }}</p>
                            }
                          </div>
                        </div>
                      </div>

                      <div class="row tw-pt-3">
                        <div class="col-3">
                          <label class="required">Country</label>
                          <select
                            #country
                            class="form-select w-10"
                            formControlName="ctpyCountry"
                            (change)="onCountryChange($event)"
                          >
                            <option value="">- Select -</option>
                            @for (code of countryData; track code.Country) {
                              <option [value]="code.Code">
                                {{ code.Country }}
                              </option>
                            }
                          </select>
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyCountry')?.errors) {
                              <p>{{ formErrors.country }}</p>
                            }
                          </div>
                        </div>
                        <div class="col-3">
                          <label class="required">State</label>
                          <select class="form-select" formControlName="ctpyState" [required]="this.isVendor">
                            <option value="">- Select -</option>
                            @for (item of stateList; track $index) {
                              <option [value]="item.Code">
                                {{ item.State }}
                              </option>
                            }
                          </select>
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyState')?.errors) {
                              <p>{{ formErrors.state }}</p>
                            }
                          </div>
                        </div>
                        <div class="col-3">
                          <label>Email To</label>
                          <input type="text" class="form-control" formControlName="toField" />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.toField')?.errors) {
                              <p>{{ formErrors.emailId }}</p>
                            }
                          </div>
                        </div>
                        <div class="col-3">
                          <label>Email CC</label>
                          <input type="text" class="form-control" formControlName="cc" />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.cc')?.errors) {
                              <p>{{ formErrors.emailId }}</p>
                            }
                          </div>
                        </div>
                      </div>

                      <div class="row tw-pt-3">
                        <div class="col-3">
                          <label class="required">TelePhone Number</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="ctpyContactNo"
                            appInputText
                            regex="[^+0-9-]"
                          />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyContactNo')?.errors) {
                              <p>{{ formErrors.telePhone }}</p>
                            }
                          </div>
                        </div>
                        <div class="col-3">
                          <label>Mobile No</label>
                          <input
                            type="text"
                            class="form-control"
                            formControlName="mobileNumber"
                            appInputText
                            regex="[^+0-9-]"
                            maxlength="20"
                          />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.mobileNumber')?.errors) {
                              <p>{{ formErrors.mobileNumber }}</p>
                            }
                          </div>
                        </div>
                        <div class="col-3">
                          <label>Email</label>
                          <input type="text" class="form-control" formControlName="ctpyEmail" />
                          <div class="input-error">
                            @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyEmail')?.errors) {
                              <p>{{ formErrors.emailId }}</p>
                            }
                          </div>
                        </div>
                      </div>

                      <div class="row tw-pt-3">
                        @if (categoryValue !== 'Customer') {
                          <div class="col-6">
                            <label class="required">MSIC</label>

                            <base-search-select
                              class="form-select sm !tw-pr-0"
                              [options]="msicList"
                              [caret]="false"
                              [placeholder]="'-Select-'"
                              [value]="
                                getMsicValue(addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyMsic')?.value)
                              "
                              (updateEvent)="setMsicValue($event)"
                              [required]="isVendor"
                            />

                            <div class="input-error">
                              @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyMsic')?.errors) {
                                <p>Enter valid Classification Code MSIC.</p>
                              }
                            </div>
                          </div>
                          <div class="col-6">
                            <label class="required">Business description</label>
                            <input
                              type="text"
                              formControlName="ctpyActivityDesc"
                              class="form-control"
                              [required]="isVendor"
                            />
                            <div class="input-error">
                              @if (addMasterCustomerForm.get('lineItems')?.get($index + '.ctpyActivityDesc')?.errors) {
                                <p>Enter valid Business Activity Description.</p>
                              }
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                    <!-- //Shipping divs -->
                    @if (categoryValue === 'Customer' && shippingLists[$index].current) {
                      <div>
                        <p>Shipping</p>

                        <!-- Row 1 -->
                        <div class="row tw-pt-3">
                          <div class="col-3">
                            <label>Recipient Name</label>
                            <input type="text" class="form-control" formControlName="srName" appInputText />
                            <div class="input-error">
                              @if (addMasterCustomerForm.get('lineItems')?.get($index + '.srName')?.errors) {
                                <p>{{ formErrors.shippingRecipient }}</p>
                              }
                            </div>
                          </div>
                          <div class="col-3">
                            <label>Recipient TIN</label>
                            <input
                              type="text"
                              class="form-control"
                              formControlName="srTin"
                              appInputText
                              regex="[^a-zA-Z0-9]"
                              [minlength]="9"
                              [maxlength]="14"
                            />
                            <div class="input-error">
                              @if (addMasterCustomerForm.get('lineItems')?.get($index + '.srTin')?.errors) {
                                <p>{{ formErrors.recipientTin }}</p>
                              }
                            </div>
                          </div>
                          <div class="col-3">
                            <label>Identification Type</label>
                            <select class="form-select" formControlName="srROrIdType">
                              <option value="">- Select -</option>
                              @for (option of counterPartyIDType; track $index) {
                                <option [value]="option">{{ option }}</option>
                              }
                            </select>
                          </div>
                          <div class="col-3">
                            <label>Identification Number</label>
                            <input type="text" class="form-control" formControlName="srRonOrIdNoOrPassportNo" />
                            <div class="input-error">
                              @if (
                                addMasterCustomerForm.get('lineItems')?.get($index + '.srROrIdType')?.value === 'BRN'
                              ) {
                                <p>Indentification no. must be within 20 Characters.</p>
                              } @else if (
                                addMasterCustomerForm.get('lineItems')?.get($index + '.srROrIdType')?.value ===
                                  'NRIC' ||
                                addMasterCustomerForm.get('lineItems')?.get($index + '.srROrIdType')?.value ===
                                  'ARMY' ||
                                addMasterCustomerForm.get('lineItems')?.get($index + '.srROrIdType')?.value ===
                                  'Passport'
                              ) {
                                <p>Indentification no. must be in 12 Characters.</p>
                              }
                            </div>
                          </div>
                        </div>

                        <!-- Row 2 -->
                        <div class="row tw-pt-3">
                          <div class="col-3">
                            <label>Address Line 0</label>
                            <input type="text" class="form-control" formControlName="srAddrLine0" appInputText />
                          </div>
                          <div class="col-3">
                            <label>Address Line 1</label>
                            <input type="text" class="form-control" formControlName="srAddrLine1" appInputText />
                          </div>
                          <div class="col-3">
                            <label>Address Line 2</label>
                            <input type="text" class="form-control" formControlName="srAddrLine2" appInputText />
                          </div>
                          <div class="col-3">
                            <label>Postal Zone</label>
                            <input type="text" class="form-control" formControlName="srPostalZone" appInputText />
                          </div>
                        </div>

                        <!-- Row 3 -->
                        <div class="row tw-pt-3">
                          <div class="col-3">
                            <label>City Name</label>
                            <input type="text" class="form-control" formControlName="srCityName" appInputText />
                          </div>
                          <div class="col-3">
                            <label>State</label>
                            <select class="form-select" formControlName="srState">
                              <option value="">- Select -</option>
                              @for (item of shippingStateList; track $index) {
                                <option [value]="item.State">{{ item.State }}</option>
                              }
                            </select>
                          </div>
                          <div class="col-3">
                            <label>Country</label>
                            <select #country class="form-select sm" formControlName="srCountry">
                              <option value="">- Select -</option>
                              @for (code of countryData; track code.Country) {
                                <option [value]="code.Code">
                                  {{ code.Country }}
                                </option>
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    }
                  </div>

                  <div class="tw-flex tw-gap-3">
                    @if (categoryValue === 'Customer') {
                      @if (!shippingLists[$index].current) {
                        <button class="btn btn-xs btn-outline-primary" (click)="addShipping($index)">
                          <span>Add Shipping</span>
                        </button>
                      } @else if (shippingLists[$index].current) {
                        <button class="btn btn-xs btn-outline-primary" (click)="removeShipping($index)">
                          <span>Remove Shipping</span>
                        </button>
                      }
                    }
                    <button class="btn btn-xs btn-outline-danger" (click)="removeItem($index)">
                      <span>Delete Item</span>
                    </button>
                  </div>
                </div>
              </div>
            }
          </div>

          <div class="tw-p-4 flex-inline">
            <button class="btn btn-outline-primary" (click)="addLineItems()">
              <span>Add Item</span>
            </button>

            <button
              class="btn btn-primary"
              [disabled]="!(this.addMasterCustomerForm.valid && this.addMasterCustomerForm.value.lineItems.length > 0)"
              (click)="addUpdateVendor('ACTIVE')"
            >
              <span>Save</span>
            </button>
            <button
              class="btn btn-primary"
              [disabled]="this.addMasterCustomerForm.controls.category.invalid"
              (click)="addUpdateVendor('DRAFT')"
            >
              <span>Save As Draft</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
