export const fieldErrors: { [key: string]: string } = {
  tin: 'TIN is invalid or empty',
  companyName:
    'Must be between 3 and 300 characters. Alphabets, numbers, underscore, parentheses, and periods are allowed.',
  brNumber: 'BRN is invalid or empty',
  pobCode: 'POB Code is invalid or empty',
  pobName: 'Must be between 3 and 300 characters. Alphabets, numbers and underscore are allowed.',

  salesTaxNumber: 'Must be in the format: XXX-XXXX-XXXXXXXX and upto 17 characters.',
  serviceTaxNumber: 'Must be in the format: XXX-XXXX-XXXXXXXX and upto 17 characters.',
  tourismTaxNumber: 'Must be in the format: XXX-XXXX-XXXXXXXX and upto 17 characters.',
  address0: 'Address is required.',
  address1: 'Address is required.',
  address3: 'Address is invalid',
  city: 'City is required within 50 Character',
  state: 'State is required',
  country: '',
  postalCode: 'Postal code is invalid',
  internalReferenceId: 'Internal reference id is invalid',
  emailId: 'Email is invalid',
  contactNumber: 'Contact number is invalid or empty',
  msicCodesList: '',

  //master error code
  category: 'Category is required',
  counterPartyName: 'Counterparty name is invalid or empty',
  counterPartyNo1: 'Counterparty ID number must be within 20 character',
  counterPartyNo2: 'Counterparty ID Number must be 12 Characters',
  sstRegistrationNumber: 'Must be in the format: XXX-XXXX-XXXXXXXX and upto 17 characters.',
  postalZone: 'Postal code is required within 50 Character',
  telePhone: 'Must be within 20 characters',
  identificationNumber: 'Identification number is invalid',
  recipientTin: 'Recipient TIN must be within 9-14 Character',
  MobileNumber: 'Mobile number is invalid',
  address: 'Address is required within 150 Character',
  emailLenght: 'Email must be within 320 Character',
  uniqueIdentifier: 'Unique identifier is invalid',
  shippingRecipient: 'Shipping recipient is invalid ',
  classification: 'Classification: 3 characters required',
  description: 'Description: max 300 characters',
  productTariffCode: 'Product Tariff Code: max 12 characters',
  countryOfOrigin: 'Country of Origin: max 50 characters',
  measurement: 'Measurement: max 5 characters',
  itemLevelGenericField1: 'Item Level Generic Feild1: No Space at start',
};
