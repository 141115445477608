import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { Vendor } from '@app/types/taxpayer.type';
import { ApiResp } from '@app/types/global.type';
import { BaseService, FormResp } from './base.service';

export interface VendorResp {
  vendorDetails: Vendor[];
  totalCount: number;
}

import { Product } from '@app/types/taxpayer.type';
import { downloadBlob } from '@app/utils/misc';

interface Products {
  productMasterDetails: Product[];
  totalCount: number;
}

export interface ProductVendorPayload {
  page: number;
  size: number;
  [key: string]: number | boolean | string;
}

interface UpdateVendor {
  issuerTin: string;
  category: string;
  ctpyTin: string;
  ctpyName: string;
  ctpyIdType: string;
  ctpyIdNo: string;
  lineItems: LineItem[];
  status: 'DRAFT' | 'ACTIVE';
}

interface LineItem {
  ctpyTtrNo: string;
  uniqueIdentifier: string;
  ctpySstrNo: string;
  ctpyMsic: string;
  ctpyActivityDesc: string;
  ctpyAddrLine0: string;
  ctpyAddrLine1: string;
  ctpyAddrLine2: string;
  ctpyPostalZone: string;
  ctpyCityName: string;
  ctpyState: string;
  ctpyCountry: string;
  ctpyEmail: string;
  toField: string;
  cc: string;
  ctpyContactNo: string;
  mobileNumber: string;
  srName: string;
  srTin: string;
  srROrIdType: string;
  srRonOrIdNoOrPassportNo: string;
  srAddrLine0: string;
  srAddrLine1: string;
  srAddrLine2: string;
  srPostalZone: string;
  srCityName: string;
  srState: string;
  srCountry: string;
}
interface UpdateProduct {
  classification: string;
  description: string;
  productTariffCode: string;
  countryOfOrigin: string;
  measurement: string;
  itemLevelGenericField1: string;
}

@Injectable({
  providedIn: 'root',
})
export class MastersService extends BaseService {
  // Vendor-Customer Master
  getVendors(payload: ProductVendorPayload): Observable<VendorResp> {
    return this.httpClient.post<ApiResp>('/myportal/search/vendor', payload).pipe(
      map((res) => {
        if (res.status) return res.response;
        this.toastr.error(res.message ?? 'Error');
        return null;
      }),
    );
  }

  getVendorById(payload: { ids: number[]; page: number; size: number }): Observable<VendorResp> {
    return this.httpClient.post<ApiResp>('/myportal/search/vendor', payload).pipe(
      map((res) => {
        if (res.status) return res.response;
        this.toastr.error(res.message ?? 'Error');
        return null;
      }),
    );
  }

  downloadVendor(ids: number[]) {
    const payload = { ids };
    return this.httpClient
      .post('/myportal/vendor/download-csv', payload, {
        responseType: 'blob',
      })
      .pipe(map((resp) => downloadBlob(resp, 'application/csv', 'VendorList.csv')));
  }

  deleteVendor(ids: number[]): Observable<boolean> {
    return this.httpClient
      .delete<ApiResp>('/myportal/vendor', { params: { ids } })
      .pipe(map((resp) => this.booleanResp(resp)));
  }

  searchVendor(payload: {
    categories: string[];
    searchValue: string;
    page: number;
    size: number;
  }): Observable<VendorResp> {
    return this.httpClient.post<ApiResp>('/myportal/search/vendor', payload).pipe(
      map((res) => {
        return res.status ? res.response : null;
      }),
    );
  }

  uploadVendor(payload: FormData) {
    return this.httpClient.post<ApiResp>('/myportal/vedormaster/upload', payload).pipe(
      map((resp) => {
        if (resp.fieldErrors) {
          this.toastr.error(resp.fieldErrors[0].message);
          return null;
        }
        return resp.status ? resp.response : [];
      }),
    );
  }

  addVendor(payload: UpdateVendor) {
    return this.httpClient
      .post<ApiResp>('/myportal/vendor', payload)
      .pipe(map((resp) => this.formResp(resp)));
  }

  updateVendor(payload: UpdateVendor, id: number) {
    return this.httpClient.put<ApiResp>(`/myportal/vendor/${id}`, payload).pipe(
      map((resp) => {
        if (resp.status) this.toastr.success(resp.message);
        else this.toastr.error(resp.message);
        return resp;
      }),
    );
  }

  //product Master
  getProductDetails(payload: ProductVendorPayload): Observable<Products> {
    return this.httpClient.post<ApiResp>('/myportal/search/product', payload).pipe(
      map((resp) => {
        if (resp.status) return resp.response;
        this.toastr.error(resp.message ?? 'Error');
        return null;
      }),
    );
  }

  downloadProducts(ids: number[]) {
    const payload = { ids };
    return this.httpClient
      .post('/myportal/product/download-csv', payload, { responseType: 'blob' })
      .pipe(map((resp) => downloadBlob(resp, 'application/csv', 'ProductList.csv')));
  }

  deleteProduct(ids: number[]): Observable<boolean> {
    return this.httpClient
      .delete<ApiResp>('/myportal/product', { params: { ids } })
      .pipe(map((resp) => this.booleanResp(resp)));
  }

  createProduct(payload: UpdateProduct) {
    return this.httpClient
      .post<ApiResp>('/myportal/product', payload)
      .pipe(map((resp) => this.booleanResp(resp)));
  }

  addProduct(payload: UpdateProduct): Observable<FormResp> {
    return this.httpClient
      .post<ApiResp>(`/myportal/product`, payload)
      .pipe(map((resp) => this.formResp(resp)));
  }

  updateProduct(productId: number, payload: UpdateProduct): Observable<boolean> {
    const options = productId;
    return this.httpClient
      .put<ApiResp>(`/myportal/product/${options}`, payload)
      .pipe(map((resp) => this.booleanResp(resp)));
  }

  uploadProduct(payload: FormData): Observable<FormResp> {
    return this.httpClient
      .post<ApiResp>('/myportal/product/upload', payload)
      .pipe(map((resp) => this.formResp(resp)));
  }

  getSharedToVendors(options: { vendorId: number; page: number; size: number }) {
    return this.httpClient
      .get<ApiResp>(`/myportal/shareDetails`, { params: options })
      .pipe(map((res) => (res.status ? res.response : null)));
  }

  shareVendor(params: { vendorId: number }, emails: string[]) {
    return this.httpClient
      .post<ApiResp>(`/myportal/share`, emails, { params })
      .pipe(map((res) => this.handleFormResp(res)));
  }
}
